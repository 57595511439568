import React from "react";
import { navigate } from "gatsby-link";

import Layout from "../../components/Layout";

import Sidenav from "../../components/dashboard/sidenav";
import DashboardBody from "../../components/dashboard/dashboardbody";

import "../../components/Styles/Custom.scss";
import "../../components/Styles/Dashboard.scss";

import jwt from "jsonwebtoken";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    if (typeof window !== "undefined") {
      // let token = localStorage.getItem('userToken');
      //   jwt.verify(token, '0a6b944d-d2fb-46fc-a85e-0295c986cd9f', function(err, decoded) {
      //         if(decoded !== undefined) {
      //            navigate('/dashboard')
      //         }else{
      //           navigate('/login')
      //         }
      //   });
      if (localStorage.getItem("username") === null) {
        navigate("/login");
      } else {
        var now = new Date;
        var timestamp_now = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
          now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

        // var timestamp_now = Math.floor(Date.now() / 1000);
        let token = localStorage.getItem('username');
        token = JSON.parse(token)

        if (timestamp_now <= token.expires) {
          navigate("/dashboard");
        } else {
          navigate("/login");
        }
      }
    }
  }
  render() {
    // return <div />;
    return (
      <Layout location="dashboard">
        <Sidenav />
        <DashboardBody />
      </Layout>
    );
  }
}
